import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withModal, withConfig } from '@mulesoft/exchange-react-hooks';
import RootStoreContext from '~/contexts/RootStoreContext';
import { selectors as clientApplicationsSelectors } from '~/domains/clientApplications';
import { selectors as commonSelectors } from '~/domains/common';
import { selectors as customizationSelectors } from '~/domains/customization';
import withFocusRestore from '~/components/WithFocusRestore';
import withLayoutContext from '~/components/LayoutContextProvider';
import { API_METRICS_DASHBOARD_ENABLED_FLAG } from '~/domains/featureFlags/keys';
import ClientApplicationDetail from './ClientApplicationDetail';

const mapStateToProps = (state, { featureFlags, match: { params } }) => {
  const application = clientApplicationsSelectors.application(
    state,
    params.applicationId
  );

  return {
    showMetrics: featureFlags[API_METRICS_DASHBOARD_ENABLED_FLAG],
    profile: commonSelectors.profile(state),
    authorization: commonSelectors.context(state).authorization,
    isFetchingApplication:
      clientApplicationsSelectors.isFetchingApplication(state),
    application,
    domain: customizationSelectors.organizationDomain(state)
  };
};

export default compose(
  withConfig,
  withModal,
  withLayoutContext(),
  withRouter,
  connect(mapStateToProps, null, null, {
    context: RootStoreContext
  }),
  withFocusRestore
)(ClientApplicationDetail);
