import '~/client/polyfills';

// prefetching api console components
import '@mulesoft/api-console-components';
import '@mulesoft/api-console-components-pre';

// include external sources styles in final stylesheet
import '@mulesoft/anypoint-components/raw/LumeTheme.css';
import '@mulesoft/exchange-ui-components/dist/exchange-ui-components.css';
import 'prismjs/themes/prism.css';
import '~/components/ErrorPage/ErrorPage.css';

// audit performance issues
import './perfAudits';

/* eslint-disable import/first */
import { StyleSheet } from 'aphrodite';
import config from 'config';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';

import { loadableReady } from '@loadable/component';
import * as assetsMiddleware from '@mulesoft/exchange-ui-portals-store/lib/domains/assets/middleware';
import configureStore from '~/store/configureStore';
import { actions as analyticsActions } from '~/domains/analytics';
import analyticsMiddleware from '~/domains/analytics/middleware';
import { selectors as commonSelectors } from '~/domains/common';
import { handleError } from '~/domains/errors/actions';
import { actions as localStorageActions } from '~/domains/localStorage';
import configurePortalsStore from '~/portals/store';
import { parseState } from '~/utils/state';
import { RenderProvider } from './provider';
import { renderHomeFooter } from './homeFooter';
import { renderNavbar } from './navbar';
/* eslint-enable import/first */

// setup webpack public path: https://webpack.js.org/guides/public-path/#on-the-fly
__webpack_public_path__ = global.__PUBLIC_PATH__; // eslint-disable-line

const history = createBrowserHistory();
const initialState = global.__INITIAL_STATE__
  ? parseState(global.__INITIAL_STATE__)
  : {};
const portalsState = global.__PORTAL_STATE__
  ? parseState(global.__PORTAL_STATE__)
  : {};
const store = configureStore(history, initialState);
const state = store.getState();
const profile = commonSelectors.profile(state);
const portalsStoreMiddleware = config.analytics.apiKey
  ? [analyticsMiddleware(), assetsMiddleware.navBarRecentItemsProvider]
  : [assetsMiddleware.navBarRecentItemsProvider];
const errorHandler = (error, actionFn) =>
  store.dispatch(handleError(error, actionFn));

if (profile) {
  store.dispatch(analyticsActions.analyticsLogin(profile));
}

StyleSheet.rehydrate(global.__APHRODITE_RENDERED_CLASS_NAMES__ || []);

const componentElement = document.getElementById('react-component-view');

const portalsStore = configurePortalsStore(
  errorHandler,
  portalsStoreMiddleware,
  { ...portalsState, session: state.session }
);

// for troubleshooting purposes
global.store = store;
global.portalsStore = portalsStore;

loadableReady(() => {
  ReactDOM.hydrate(
    <RenderProvider
      suppressHydrationWarning
      store={store}
      portalsStore={portalsStore}
      history={history}
      errorHandler={errorHandler}
    />,
    componentElement,
    () => store.dispatch(localStorageActions.init())
  );

  renderNavbar(store, portalsStore);
  renderHomeFooter(store);
});
