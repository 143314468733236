import { format } from 'url';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import BackIcon from '@mulesoft/anypoint-icons/lib/svg/back-small.svg';
import Icon from '@mulesoft/anypoint-icons/lib/Icon';
import { SkipLinks, ErrorBoundary } from '@mulesoft/exchange-ui-components';
import Actions from '../Actions';
import styles from './ClientApplicationDetail.css';

const Loading = () => {
  return (
    <div className={styles.spinner} data-test-id="app-details-spinner">
      <Spinner size="l" />
    </div>
  );
};

const ApplicationDetails = loadable(
  () =>
    import(
      /* webpackChunkName: "client-applications" */ '@mulesoft/client-applications-ui/raw/app'
    ),
  { ssr: false, fallback: <Loading /> }
);

ApplicationDetails.displayName = 'ApplicationDetails';

class ClientApplicationDetail extends React.PureComponent {
  static propTypes = {
    application: PropTypes.object,
    authorization: PropTypes.string,
    domain: PropTypes.string,
    config: PropTypes.object,
    getPath: PropTypes.func,
    isFetchingApplication: PropTypes.bool,
    profile: PropTypes.object,
    showMetrics: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { config } = props;

    this.exchangeApiHost = format(config.api.uri);
    this.anypointHost = format(config.platform.uri);
  }

  render() {
    const {
      application,
      authorization,
      domain,
      getPath,
      isFetchingApplication,
      profile,
      showMetrics
    } = this.props;

    if (!application) {
      return null;
    }

    return (
      <section className={styles.container} aria-label={application.name}>
        <SkipLinks />
        <h1 className="visually-hidden">{application.name}</h1>
        <header className={styles.header}>
          <Link
            data-test-id="back-to-asset-list"
            key="back-to-asset-list"
            className={styles.navigationLink}
            to={getPath('applications', {
              organization: profile.organization
            })}
          >
            <Icon aria-hidden size="xs" className={styles.icon}>
              <BackIcon />
            </Icon>
            <span className={styles.label}>My applications list</span>
          </Link>
          <Actions
            display-if={!isFetchingApplication}
            className={styles.actions}
            application={application}
          />
        </header>
        {isFetchingApplication ? (
          <Loading />
        ) : (
          <ErrorBoundary>
            <ApplicationDetails
              domain={domain}
              application={application}
              authorization={authorization}
              anypointHost={this.anypointHost}
              exchangeApiHost={this.exchangeApiHost}
              showMetrics={showMetrics}
              testId="application-detail-content"
            />
          </ErrorBoundary>
        )}
      </section>
    );
  }
}

export default ClientApplicationDetail;
