import PropTypes from 'prop-types';
import config from 'config';
import React from 'react';
import loadable from '@loadable/component';
import Spinner from '@mulesoft/anypoint-components/lib/Spinner';
import { AssetPropType } from '@mulesoft/exchange-react-shapes';
import { SkipLinks } from '@mulesoft/exchange-ui-components';
import { isAssetsLimitReached } from '~/utils/assetsLimitWarning';
import styles from './CreateAsset.css';

const Loading = () => {
  return (
    <div className={styles.spinner} data-test-id="create-form-spinner">
      <Spinner size="l" />
    </div>
  );
};

export const PublishFormV1 = loadable(
  () =>
    import(/* webpackChunkName: "publish-form-v1" */ './DeprecatedPublishForm'),
  { fallback: <Loading /> }
);

export const PublishFormV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "publish-form-v2" */ '@mulesoft/exchange-publish-form'
    ),
  { fallback: <Loading /> }
);

class CreateAsset extends React.PureComponent {
  static propTypes = {
    organization: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onShowAssetLimitModal: PropTypes.func.isRequired,
    onCreateSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    asset: AssetPropType,
    context: PropTypes.object,
    groupId: PropTypes.string,
    isNewVersion: PropTypes.bool,
    isPublishFormV2Enabled: PropTypes.bool,
    assetId: PropTypes.string,
    allowSnapshots: PropTypes.bool,
    assetsLimit: PropTypes.object
  };

  static defaultProps = { isNewVersion: false, isPublishFormV2Enabled: false };

  state = {
    exchangeXAPIUri: config.api.uri
  };

  componentDidMount() {
    const { onShowAssetLimitModal, asset, assetsLimit } = this.props;

    if (isAssetsLimitReached(assetsLimit)) {
      onShowAssetLimitModal(asset, assetsLimit.assetCountLimit);
    }
  }

  render() {
    const {
      context,
      onCancel,
      onCreateSuccess,
      onError,
      asset,
      groupId,
      assetId,
      organization,
      allowSnapshots,
      isNewVersion,
      isPublishFormV2Enabled
    } = this.props;

    return (
      <section
        className={styles.createAsset}
        data-test-id="create-asset"
        aria-label="Create asset"
      >
        <SkipLinks />
        {!isPublishFormV2Enabled ? (
          <PublishFormV1
            cancelButtonText="Cancel"
            createButtonText="Publish"
            isDesignCenterDeveloper={organization.isDesignCenterDeveloper}
            titleText={
              isNewVersion ? 'Add a new version' : 'Publish a new asset'
            }
            availableAssetTypes={config.availableAssetTypes}
            exchangeXAPIUri={this.state.exchangeXAPIUri}
            clientName={config.serviceName}
            asset={asset}
            groupId={groupId}
            assetId={assetId}
            allowSnapshots={allowSnapshots}
            onCreateSuccess={onCreateSuccess}
            onCancel={onCancel}
            onError={onError}
            organizationId={organization.id}
            authorization={context.authorization}
          />
        ) : (
          <PublishFormV2
            latestAsset={asset}
            onCreateSuccess={onCreateSuccess}
            onCancel={onCancel}
          />
        )}
      </section>
    );
  }
}

export default CreateAsset;
