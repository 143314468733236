export const ANG_SEARCH_ENABLED_FLAG = 'ang-search-enabled';
export const API_METRICS_DASHBOARD_ENABLED_FLAG =
  'deprecation-api-dashboard-metrics';
export const SNAPSHOTS_ENABLED_FLAG = 'snapshots-enabled';
export const APIC_PRE_RELEASE_ENABLED = 'apic-pre-release-enabled';
export const ONE_TRUST_ENABLED = 'one-trust-enabled';
export const ONE_TRUST_PRODUCTIVE_SCRIPT = 'one-trust-productive-script';
export const PUBLISH_FORM_V2_ENABLED = 'publish-form-v2-enabled';
export const PORTAL_RE_ARCHITECTURE_ENABLED = 'portal-re-architecture-enabled';
export const PUBLIC_PORTAL_PIRA_ENABLED = 'public-portal-pira-enabled';
export const ASSETS_DEFINITIONS_VERSION = 'assets-definitions-version';
export const FEDERATED_ASSETS_DEFINITIONS_ENABLED =
  'federated-assets-definitions-enabled';
export const GLOBAL_ANG_SEARCH_ENABLED = 'global-ang-search-enabled';
export const REUSE_METRICS_ENABLED = 'reuse-metrics-enabled';
export const ANG_DEFAULT_ENABLED = 'ang-default-enabled';
export const PORTAL_SEARCH_ENABLED = 'portal-search-enabled';
export const ANG_CUSTOM_FIELDS_ENABLED = 'ang-custom-fields-enabled';
export const SEARCH_EXPERIMENT = 'search-experiment';
export const LUME_THEME_ENABLED = 'lume-theme-enabled';
